export default {
  blue: "#888",
  colors: {
    primary: "#ff4900",
    secondary: "#fff",
    gray: "#111",
  },
  size: {
    mobile: "360px",
    tablet: "768px",
    laptopS: "1024px",
    laptop: "1366px",
    desktop: "1920px",
  },
}
